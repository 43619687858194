// @ts-ignore
import React from 'react';

import { createConfirmation } from '@ventera-corporation/vstart-components-react';

interface ModalSuccessProps {
  onAdded: () => void;
  isEdit: boolean;
}

export function getModalSuccess({ onAdded, isEdit }: ModalSuccessProps) {
  return createConfirmation({
    callback: onAdded,
    actions: [{ label: 'Return to Cases', value: '' }],
    children: <p>The case has been {isEdit ? 'modified' : 'added'}</p>,
  });
}
