// @ts-ignore
import React from 'react';

import { Container } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import {
  VButton,
  usePageUpdate,
  useVSkipNav,
  VLoader,
  VForm,
  VSingleColView,
  VInputField,
} from '@ventera-corporation/vstart-components-react';

import * as API from 'api/pages/routes';

import { PAGE_ADD_CASE, PAGE_EDIT_CASE } from 'app/site-map';

import { InternalServerError } from 'modules/error/pages/internal-server-error/InternalServerError';

import { CaseDto } from '../cases/Cases.types';

import { getModalCancel } from './utils/get-modal-cancel';
import { getModalSuccess } from './utils/get-modal-success';

interface CaseQuery {
  data: CaseDto;
}

function AddEditCase() {
  const params = useParams();
  const navigate = useNavigate();
  const isEdit = !!params?.id;
  const {
    data = {} as CaseQuery,
    error,
    isLoading,
  } = useQuery(['case'], () => API.getCase(params?.id || ''));
  useVSkipNav('add-edit-case');
  usePageUpdate({
    documentTitle: isEdit ? PAGE_EDIT_CASE() : PAGE_ADD_CASE(),
    header: {},
  });

  if (isEdit && error) {
    return <InternalServerError error={error as Error} />;
  }

  if (isEdit && isLoading) return <VLoader />;

  const defaultValues = data?.data || {};

  const showCancelModal = () => {
    getModalCancel();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submit = async (payload: any) => {
    if (!isEdit) {
      const { data: added } = await API.addCase(payload);
      if (added) {
        getModalSuccess({
          isEdit,
          onAdded,
        });
      }
    } else if (params?.id) {
      const { data: edited } = await API.updateCase(params.id, payload);
      if (edited) {
        getModalSuccess({
          isEdit,
          onAdded,
        });
      }
    }
  };

  const onAdded = () => {
    navigate('/cases');
  };

  return (
    <Container>
      <div id="add-edit-case" className="row">
        <div className="col">
          <h1 className="mb-4 font-weight-normal">
            Form I-131 - Reentry Permit
          </h1>
          <div className="card shadow">
            <div className="card-body">
              <VForm
                onSubmit={submit}
                defaultValues={defaultValues}
                className="addEditCase"
              >
                <VSingleColView size="full" className="mt-4">
                  <h2 className="font-weight-bold border-bottom pb-1 mb-4">
                    {isEdit ? 'Edit Case' : 'Add Case'}
                  </h2>
                </VSingleColView>

                <VSingleColView size="full" className="mt-4">
                  <h4 className="font-weight-bold mb-4">
                    Personal Information
                  </h4>
                </VSingleColView>

                <VSingleColView size="full">
                  <h5 className="mb-3">Application Name</h5>
                </VSingleColView>
                <div className="row">
                  <div className="col-md-4 pb-2">
                    <VInputField
                      // @ts-ignore
                      name="givenName"
                      label="Given Name/First Name"
                      required
                    />
                  </div>
                  <div className="col-md-4 pb-2">
                    <VInputField
                      // @ts-ignore
                      name="middleName"
                      label="Middle Name"
                    />
                  </div>
                  <div className="col-md-4 pb-2">
                    <VInputField
                      // @ts-ignore
                      name="familyName"
                      label="Family/Last Name"
                      required
                    />
                  </div>
                </div>
                <VSingleColView className="mt-4">
                  <h4 className="font-weight-bold my-3">Other Information</h4>
                </VSingleColView>

                <VSingleColView size="three-quarter">
                  <VInputField
                    prependText="A-"
                    // @ts-ignore
                    name="alienRegistrationNumber"
                    label="Alien Registration Number (A-Number)"
                    required
                  />
                </VSingleColView>
                <VSingleColView size="three-quarter" className="mt-2">
                  <VInputField
                    // @ts-ignore
                    name="classOfAdmission"
                    label="Class of Admission"
                    required
                  />
                </VSingleColView>
                <VSingleColView className="mt-4">
                  <VButton className="me-2" type="submit">
                    Save
                  </VButton>
                  <VButton variant="outline-primary" onClick={showCancelModal}>
                    Cancel
                  </VButton>
                </VSingleColView>
              </VForm>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default AddEditCase;
