// @ts-ignore
import React from 'react';

import { Container } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  VButton,
  usePageUpdate,
  useVSkipNav,
  VLoader,
} from '@ventera-corporation/vstart-components-react';

import * as API from 'api/pages/routes';

import { PAGE_HOME, PAGE_EDIT_CASE } from 'app/site-map';

import { InternalServerError } from 'modules/error/pages/internal-server-error/InternalServerError';

import { CaseDto } from '../cases/Cases.types';

import './ViewCase.scss';

interface CaseQuery {
  data: CaseDto;
}

function ViewCase() {
  const params = useParams();
  const {
    data = {} as CaseQuery,
    error,
    isLoading,
  } = useQuery(['case'], () => API.getCase(params?.id || ''));
  useVSkipNav('details');
  usePageUpdate({
    documentTitle: PAGE_HOME.title,
    header: {},
  });

  if (error) return <InternalServerError error={error as Error} />;

  if (isLoading) return <VLoader />;

  const item = data?.data || {};

  return (
    <Container>
      <h1 id="details">Receipt {item.receiptNumber}</h1>
      <div className="heading py-2">
        <h2>Case Information</h2>
        <VButton to={PAGE_EDIT_CASE({ id: params.id })}>Edit</VButton>
      </div>

      <hr />
      <h3>Personal Information</h3>
      <h4>What's Your Name</h4>
      <div className="nameSection">
        <section>
          <span>Given Name/First Name</span>
          <span>{item.givenName}</span>
        </section>

        <section>
          <span>Middle Name</span>
          <span>{item.middleName}</span>
        </section>

        <section>
          <span>Family/Last Name</span>
          <span>{item.familyName}</span>
        </section>
      </div>

      <h4>Other Information</h4>
      <div className="otherSection">
        <section>
          <span>Alien Registration Number (A-Number)</span>
          <span>{item.alienRegistrationNumber}</span>
        </section>

        <section>
          <span>Class of Admission</span>
          <span>{item.classOfAdmission}</span>
        </section>
      </div>
    </Container>
  );
}

export default ViewCase;
