// @ts-ignore
import React, { useEffect, useState } from 'react';

import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import {
  VLoader,
  usePageUpdate,
  useVSkipNav,
  VTableWithPagination,
  VTableHeader,
  VButton,
  useVPaginatedQuery,
  SortDirection,
} from '@ventera-corporation/vstart-components-react';

import * as API from 'api/pages/routes';
import { CaseQueryParams } from 'api/pages/routes.types';

import { PAGE_CASES, PAGE_ADD_CASE } from 'app/site-map';

import { InternalServerError } from 'modules/error/pages/internal-server-error/InternalServerError';

import { getFormattedDate } from 'utils/get-formatted-date';
import { getFullName } from 'utils/get-full-name';

import * as T from './Cases.types';
import SearchCase from './components/Search/Search';

import './Cases.scss';

function Cases() {
  const [searchValue, setSearchValue] = useState('');

  const api = async (params: CaseQueryParams) => {
    const res = await API.getCases(params);
    return res?.data;
  };
  const {
    page,
    setPage,
    sortDir,
    setSortDir,
    sortBy,
    setSortBy,
    limit,
    isLoading,
    error,
    ...data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useVPaginatedQuery<any>({
    queryKey: 'cases',
    queryFn: api,
    defaultSortDir: SortDirection.DESC,
    defaultSortBy: 'receiptNum',
    params: { receiptNumber: searchValue },
  });

  const navigate = useNavigate();

  useVSkipNav('case-management');
  usePageUpdate({
    documentTitle: PAGE_CASES.title,
    header: {},
  });

  useEffect(() => {
    if (data?.count === 1 && searchValue) {
      navigate(`/cases/${data?.data?.[0]?.id}`);
    }
  }, [data, searchValue, navigate]);

  if (error) return <InternalServerError error={error as Error} />;

  if (isLoading) return <VLoader />;

  const searchCase = ({ search }: { search: string }) => {
    setSearchValue(search);
  };

  const { data: results = [], count = 0 } = data || {};

  const columns = [
    { label: 'Receipt #', sortBy: 'receiptNum' },
    { label: 'Case Status', sortBy: 'caseStatus' },
    { label: 'Security Check Status', sortBy: 'securityStatus' },
    { label: 'Submission Date', sortBy: 'submissionDate' },
    { label: 'Last Updated Date', sortBy: 'lastUpdatedDate' },
    { label: 'Updated By', sortBy: 'updatedBy' },
  ];

  const pageData = results;

  const viewCase = (id: string) => {
    navigate(`/cases/${id}`);
  };

  return (
    <Container>
      <h1 id="case-management">Case Management</h1>
      <div id="actions">
        <SearchCase searchCase={searchCase} searchValue={searchValue} />
        <div>
          <VButton
            variant="primary"
            to={PAGE_ADD_CASE()}
            className="btn-primary"
          >
            Add Case
          </VButton>
        </div>
      </div>

      {!results.length && <p>No Match Found</p>}
      {results.length > 0 && (
        <VTableWithPagination
          responsive
          page={page}
          pages={Math.ceil(count / limit)}
          title=""
          setPage={setPage}
          count={count}
          limit={limit}
          results={pageData}
          countType={{ name: 'case', pluralName: 'cases' }}
        >
          <VTableHeader
            {...{ columns, setSortBy, sortBy, setSortDir, sortDir }}
          />
          <tbody data-testid="table-body">
            {pageData.map((row: T.CaseDto) => (
              <tr className="caseRow" key={row.id}>
                <td>
                  <VButton
                    onClick={() => viewCase(row.id)}
                    variant="link"
                    className="receiptNumber p-0"
                  >
                    {row.receiptNumber}
                  </VButton>
                </td>
                <td>{row.status}</td>
                <td>{row.securityCheckStatus}</td>
                <td>{getFormattedDate(new Date(row.createdDate))}</td>
                <td>{getFormattedDate(new Date(row.updatedDate))}</td>
                <td>{getFullName(row.updatedByDto)}</td>
              </tr>
            ))}
          </tbody>
        </VTableWithPagination>
      )}
    </Container>
  );
}

export default Cases;
