// @ts-ignore
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  VButton,
  VForm,
  VInputField,
} from '@ventera-corporation/vstart-components-react';

import './Search.scss';

interface SearchCaseProps {
  searchCase: ({ search }: { search: string }) => void;
  searchValue: string;
}

function SearchCase({ searchCase, searchValue }: SearchCaseProps) {
  return (
    <div className="m-1">
      <VForm
        onSubmit={searchCase}
        className="searchForm"
        defaultValues={{ search: searchValue }}
      >
        <div className="mb-2 input-field">
          <FontAwesomeIcon color="#00813D" icon={['fas', 'search']} size="1x" />
          <VInputField
            // @ts-ignore
            name="search"
            label="Find, manage, and review applications."
          />
        </div>
        <div className="m-2">
          <VButton size="sm" type="submit" data-testid="submit-btn">
            Submit
          </VButton>
        </div>
      </VForm>
      <span>Search by receipt number</span>
    </div>
  );
}

export default SearchCase;
