import qs from 'qs';

import { api } from 'api/api';
import { API_CASE_SERVICE } from 'api/constants';

import { CaseDto } from 'modules/case-management/pages/cases/Cases.types';

import * as T from './routes.types';

export async function getCases(queryParams: T.CaseQueryParams) {
  const { params, ...rest } = queryParams;
  const query = qs.stringify({ ...params, ...rest });
  const url = `${API_CASE_SERVICE}/case?${query}`;
  return await api.get(url);
}

export async function getCase(id: string) {
  const url = `${API_CASE_SERVICE}/case/${id}`;
  return await api.get(url);
}

export async function addCase(data: Partial<CaseDto>) {
  const url = `${API_CASE_SERVICE}/case`;
  return await api.post(url, data);
}

export async function updateCase(id: string, data: Partial<CaseDto>) {
  const url = `${API_CASE_SERVICE}/case/${id}`;
  return await api.put(url, data);
}
