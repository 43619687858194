// @ts-ignore
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import background from 'assets/images/circle.svg';
import { useLocation } from 'react-router-dom';

import {
  cognitoUrl,
  usePageUpdate,
  useVSkipNav,
  VButton,
  VDoubleColView,
  useEnvVars,
} from '@ventera-corporation/vstart-components-react';

import { PAGE_SIGN_IN, PAGE_HOME } from 'app/site-map';

import './SignInPage.scss';

function SignIn() {
  const envVars = useEnvVars();
  useVSkipNav('splash');
  usePageUpdate({
    documentTitle: PAGE_SIGN_IN.title,
    header: {},
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const location: any = useLocation();
  const { from } = location.state || {
    from: { pathname: PAGE_HOME() },
  };

  return (
    <div id="signInWrapper" data-testid="sign-in-page">
      <div id="splash">
        <div id="welcome">
          <h1>Welcome to Case Management Application - React</h1>
          <p>
            This is a simplified case management application built in Angular
            for vSTART.
          </p>
          <VButton
            href={cognitoUrl(envVars, from.pathname, true)}
            variant="outline-primary"
          >
            Sign Up
          </VButton>
          <VButton
            href={cognitoUrl(envVars, from.pathname)}
            data-testid="external-cognito-sign-in"
          >
            Sign In
          </VButton>
        </div>
      </div>
      <div id="content">
        <img src={background} alt="lead" />
        <VDoubleColView className="aboutContent">
          <div className="p-4">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon="info-circle" size="2x" />
              <h2>About vSTART</h2>
            </div>
            <p>
              vSTART is the Ventera Scaffolding, Templates, Artifacts, and
              Release Toolkit, built from Ventera's collective experience,
              ingenuity, and thought leadership. Discover the technology
              platform, playbooks, design artifacts, and more.
            </p>
          </div>

          <div className="p-4">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon="cogs" size="2x" />
              <h2>About This App</h2>
            </div>
            <p>
              The purpose of this platform app is to demonstrate basic routing,
              authentication, and CRUD operations using Angular components. The
              app is built around a simplified case management workflow and
              intended for a “case manager” user role. The app interacts with a
              basic case microservice and contains only mock data.
            </p>
          </div>
        </VDoubleColView>
      </div>
    </div>
  );
}

export default SignIn;
