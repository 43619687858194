import logo from 'assets/images/logo.svg';
import { Outlet, Route, Routes } from 'react-router-dom';

import {
  VAuthenticatedRoute,
  VHeader,
  VPageHeader,
  useUserMenu,
} from '@ventera-corporation/vstart-components-react';

import * as PAGES from 'app/site-map';

import AddEditCase from 'modules/case-management/pages/add-edit-case/AddEditCase';
import Cases from 'modules/case-management/pages/cases/Cases';
import ViewCase from 'modules/case-management/pages/view-case/ViewCase';
import { NotFound } from 'modules/error/pages/not-found/NotFound';
import SignIn from 'modules/sign-in/pages/sign-in/SignInPage';

function AppRouter() {
  const { menuItems } = useUserMenu({});

  return (
    <main className="pb-app-body" data-testid="app-router">
      <header>
        <VHeader
          navLinks={menuItems}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          logo={(<img src={logo} alt="vStart" />) as any}
          position="static"
        />
        <VPageHeader homeLinkText="Case Management" />
      </header>
      <Routes>
        <Route
          element={
            <VAuthenticatedRoute>
              <Outlet />
            </VAuthenticatedRoute>
          }
        >
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Cases />} />
          <Route path={PAGES.PAGE_CASES()} element={<Cases />} />
          <Route path={PAGES.PAGE_VIEW_CASE()} element={<ViewCase />} />
          <Route path={PAGES.PAGE_ADD_CASE()} element={<AddEditCase />} />
          <Route path={PAGES.PAGE_EDIT_CASE()} element={<AddEditCase />} />
        </Route>
        <Route path={PAGES.PAGE_SIGN_IN()} element={<SignIn />} />
      </Routes>
    </main>
  );
}

export default AppRouter;
