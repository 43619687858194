// @ts-ignore
import React from 'react';

import { createConfirmation } from '@ventera-corporation/vstart-components-react';

export function getModalCancel() {
  return createConfirmation({
    actions: [{ label: 'Close', variant: 'primary', value: '' }],
    callback: () => false,
    children: <p>Are you sure you want to cancel?</p>,
  });
}
