// @ts-ignore
import React from 'react';

import { Container } from 'react-bootstrap';

import {
  VMainLayout,
  useVSkipNav,
} from '@ventera-corporation/vstart-components-react';

export function NotFound() {
  useVSkipNav('main-content-error');

  return (
    <Container>
      <VMainLayout>
        <div id="main-content-error" className="text-center mt-5">
          <div id="error-text">
            <h1
              id="main-content-error"
              data-testid="404-error"
              className="vstart-error-page-heading"
            >
              Not Found 404
            </h1>
            <h2 className="display-4">Not Found</h2>
            <p className="mt-3">
              This page cannot be displayed because either it doesn't exist or
              you don't have permission to access it.
            </p>
          </div>
        </div>
      </VMainLayout>
    </Container>
  );
}
