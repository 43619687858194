import logo from 'assets/images/logo_white.svg';

import './Footer.scss';

function Footer() {
  return (
    <footer>
      <div className="container">
        <h2 className="visually-hidden">Footer Links</h2>
        <div className="firstRow">
          <div>
            <img src={logo} alt="vStart" width="75%" />
          </div>
          <div className="footerNav">
            <div>
              <h3>Resources</h3>
              <ul>
                <li>
                  <a href="https://miro.com/app/board/o9J_lZ-ikis=/">
                    UX Templates
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h3>Feedback</h3>
              <ul>
                <li>
                  <span className="me-3">
                    <a href="mailto:vstart@ventera.com">Contact Us</a>
                  </span>
                </li>
                <li>
                  <span>
                    <a
                      href="https://forms.gle/X6eaiHbHhCPWpoe96"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Submit Your Feedback
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div>
            <span>© 2021 Ventera Corporation</span>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
