/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiRequest } from '@ventera-corporation/vstart-components-react';

async function get(url: string) {
  const result = await apiRequest(url, { method: 'get' });
  return result;
}

async function post(url: string, data: any) {
  const result = await apiRequest(url, { method: 'post', data });
  return result;
}

async function put(url: string, data: any) {
  const result = await apiRequest(url, { method: 'put', data });
  return result;
}

export const api = {
  get,
  put,
  post,
};
