// @ts-ignore
import React, { useEffect, useState } from 'react';

import {
  VModal,
  useVSkipNav,
} from '@ventera-corporation/vstart-components-react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function InternalServerError({ error }: { error: any }) {
  const [showModal, setShowModal] = useState(false);
  useVSkipNav('main-content-error');

  useEffect(() => {
    if (error?.message) {
      setShowModal(true);
    }
  }, [error]);

  return (
    <div id="main-content-error">
      {showModal && (
        <VModal
          title="Internal Server Error"
          variant="danger"
          onAnswer={(answer: boolean) => setShowModal(answer)}
          actions={[{ label: 'Close', value: false }]}
        >
          <p>
            The server encountered an internal error or misconfiguration and was
            unable to complete your request.
          </p>

          <p>
            Please contact the server administrator at [admin@example.com] to
            inform them of the time this error occurred, and the actions you
            performed just before this error.
          </p>

          <p>
            More information about this error may be available in the server
            error log.
          </p>
        </VModal>
      )}
    </div>
  );
}
